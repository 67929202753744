import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImageLoader from 'react-imageloader';
import cx from 'classnames';
import Loading from '../components/Loading';
import Button from '../components/Button';
import s from './styles.module.css';
import { format, formatDate } from '../format';
import {
  storageActions,
  photoActions,
  appActions,
  storagePartsActions,
} from '../data/actions';
import { ACTION_START, ACTION_SUCCESS, ACTION_FAILED } from '../data/constants';
import PhotoBox from '../components/photobox';
import classes from './photoButton.module.css';
import { RequestType, StorageStatus } from '../constants';

type Props = {
  storage: any;
  getCurrentStorage: any;
  getPhotos: any;
  updateStorage: any;
  addPhotos: any;
  deletePhoto: any;
  config: any;
  photos: any;
  addPhotosStatus: any;
  makeDefaultPhoto: any;
  routeTo: any;
  getStorageParts: any;
  storageParts: any;
  requests: any;
  getPartPhotos: any;
  partPhotos: any;
  isCurrentStorageLoading: any;
  urlParams: any;
};
type State = {
  name: any;
  editMode: any;
  isPhotoOpen: boolean;
  photoIndex: null | number;
  partPhotoIndex: null | number;
  isPartPhotoOpen: boolean;
  currentPartPhotos: any;
  currentPartId: any;
  allPartsPhotos: any;
  done: boolean;
};

class StoragePage extends React.Component<Props, State> {
  static propTypes = {
    urlParams: PropTypes.object.isRequired,
    getPhotos: PropTypes.func.isRequired,
    addPhotos: PropTypes.func.isRequired,
    deletePhoto: PropTypes.func.isRequired,
    updateStorage: PropTypes.func.isRequired,
    storage: PropTypes.object.isRequired,
    photos: PropTypes.arrayOf(PropTypes.object).isRequired,
    addPhotosStatus: PropTypes.string,
    config: PropTypes.object.isRequired,
    routeTo: PropTypes.func.isRequired,
    getStorageParts: PropTypes.func.isRequired,
    storageParts: PropTypes.arrayOf(PropTypes.object).isRequired,
    requests: PropTypes.arrayOf(PropTypes.object).isRequired,
    getPartPhotos: PropTypes.func.isRequired,
    partPhotos: PropTypes.arrayOf(PropTypes.object).isRequired,
    isCurrentStorageLoading: PropTypes.bool.isRequired,
    getCurrentStorage: PropTypes.func.isRequired,
  };
  storageNameInput: any;
  fileUpload: any;
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      editMode: false,
      isPhotoOpen: false,
      photoIndex: null,
      isPartPhotoOpen: null,
      currentPartPhotos: null,
      partPhotoIndex: null,
      currentPartId: null,
      allPartsPhotos: [],
      done: false,
    };
  }

  componentDidMount() {
    const { getCurrentStorage, urlParams } = this.props;
    getCurrentStorage(urlParams.storageId);
  }

  componentDidUpdate(prevProps): void {
    const { storage, getPhotos, getStorageParts, getPartPhotos } = this.props;
    if (storage && storage !== prevProps.storage) {
      document.title = `${
        storage.name || storage.code || 'Item Details'
      } - Boxit Storage`;
      getPhotos(storage.id);
      getPartPhotos();
      getStorageParts(storage.id);
      this.setState({ name: storage.name || '' });
    }
    if (
      this.props.partPhotos.length > 0 &&
      this.props.storageParts.length > 0 &&
      !this.state.done
    ) {
      for (const part of this.props.storageParts) {
        const currentPartPhotos = this.props.partPhotos.filter(
          i => i.storageId === part.id
        );
        const finaldata = currentPartPhotos.map((item, index) => {
          return {
            id: part.id,
            default: item.default,
            code: part.code,
            photo: item.photo,
            thumbnail: item.thumbnail,
            number: `${index + 1} of ${currentPartPhotos.length}`,
          };
        });

        this.setState(prev => {
          let data = prev.allPartsPhotos;
          data = [...data, ...finaldata];
          return {
            allPartsPhotos: data,
            done: true,
          };
        });
      }
    }
  }

  openPhoto(image, index) {
    this.setState({
      isPhotoOpen: true,
      photoIndex: index,
    });
  }

  openPartPhoto(currentPartId, partPhotoIndex) {
    this.setState({
      isPartPhotoOpen: true,
      currentPartId,
      partPhotoIndex,
    });
  }

  render() {
    const {
      updateStorage,
      addPhotos,
      deletePhoto,
      config,
      storage,
      photos,
      addPhotosStatus,
      makeDefaultPhoto,
      storageParts,
      requests,
      isCurrentStorageLoading,
    } = this.props;

    if (!storage) {
      return null;
    }

    const uploading = addPhotosStatus === ACTION_START;
    const uploadSuccess = addPhotosStatus === ACTION_SUCCESS;
    const uploadFailed = addPhotosStatus === ACTION_FAILED;
    const storageType = config.storagetypes.find(
      st => st.id === storage.storagetypeId
    );
    const typeString = storageType ? storageType.name : '';
    const isValid = this.state.name.trim();

    const encodeImageFileAsURL = (file, cb) => {
      const reader = new FileReader();
      reader.onloadend = function onloadend() {
        cb(reader.result);
      };
      reader.readAsDataURL(file);
    };

    //console.log(storageParts);

    return !isCurrentStorageLoading && storage ? (
      <div>
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
          {storage.code || 'Item Details'}
        </h2>
        <div className="">
          {/* <div className="col-span-12"> */}
          <div
            className={cx(
              s.storage,
              'box lg:p-16 rounded shadow-md grid grid-cols-12'
            )}
          >
            <div
              className="col-span-12"
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                zIndex: 10, // Ensure it’s on top
               
              }}
              
            >
              <button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'Center',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: '#26AE60',
                  
                  zIndex: 10, // Ensure it’s on top

                }}
                onClick={() => {
                  this.props.routeTo('/');
                }}
                
              >
                <svg
                  style={{ height: '2rem', width: 'auto', fill: '#26AE60' }}
                  id="Layer_1"
                  version="1.1"
                  viewBox="0 0 512 512"
                  width="512px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
                </svg>
                Back
              </button>
            </div>
            <h3 className="sub-title m-b-20 col-span-12">Details</h3>
            <table className="table table-bordered table-hover col-span-12 table-auto  border-collapse border">
              <tbody>
                <tr className="border">
                  <td className="p-3">Name</td>
                  <td className="border">
                    {(storage.name || storage.storagetype.name) && !this.state.editMode && (
                      <div className={cx(s.storageName, 'p-3')}>
                        {storage.name || storage.storagetype.name  }
                        <Button
                          className="right p-0 no-bg"
                          onClick={() => {
                            this.setState({
                              editMode: true,
                            });
                            setTimeout(() => {
                              if (this.storageNameInput)
                                this.storageNameInput.focus();
                            }, 500);
                          }}
                          filled={false}
                        >
                          <i className="fa fa-pencil blue" />
                        </Button>
                      </div>
                    )}
                    {((!storage.name && !storage.storagetype.name) || this.state.editMode) && (
                      <div className="form-group m-b-0 ">
                        <div className="input-group grid grid-cols-12">
                          <input
                            type="text"
                            maxLength={25}
                            className=" col-span-11 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline input-md"
                            ref={input => {
                              this.storageNameInput = input;
                            }}
                            value={this.state.name}
                            placeholder="Item Name"
                            onChange={e => {
                              this.setState({
                                name: e.target.value,
                              });
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter' && isValid) {
                                updateStorage(storage.id, {
                                  name: this.state.name,
                                });
                                this.setState({
                                  editMode: false,
                                });
                              }
                            }}
                          />
                          <div className="input-group-addon col-span-1">
                            <Button
                              className="p-0 right w-full h-full"
                              disabled={!isValid}
                              onClick={() => {
                                updateStorage(storage.id, {
                                  name: this.state.name,
                                });
                                this.setState({
                                  editMode: false,
                                });
                              }}
                            >
                              <i className="fa fa-floppy-o blue" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
                <tr className="border">
                  <td className="border p-3 ">Code</td>
                  <td className="p-3 border">{storage.code || 'Unassigned'}</td>
                </tr>
                <tr>
                  <td className="col-span-6 p-3 border">Type</td>
                  <td className="col-span-6 p-3 border">{typeString}</td>
                </tr>
                <tr>
                  <td className="col-span-6 p-3 border">Stored From</td>
                  <td className="col-span-6 p-3 border">
                    {storage.from && formatDate(storage.from, true)}
                  </td>
                </tr>
                {false && storage.to && (
                  <tr>
                    <td className="col-span-6 p-3 border">Stored Till</td>
                    <td className="col-span-6 p-3 border ">
                      {storage.to && formatDate(storage.to, true)}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="col-span-6 p-3 border">Status</td>
                  <td className="col-span-6 p-3 border">
                    {format(storage.status)}
                  </td>
                </tr>
                {!storage.storagetype.tracking && (
                  <tr>
                    <td className="col-span-6 p-3 border">Quantity on Hand</td>
                    <td className="col-span-6 p-3 border">{storage.qty}</td>
                  </tr>
                )}
              </tbody>
            </table>
            {/*<div>
              {storageParts.map(part => {
                return <span key={part.id}>{part.id}</span>;
              })}
            </div>*/}
            <h3 className="sub-title m-t-10 m-b-20 col-span-12">Photos</h3>
            <div className="grid grid-cols-12 col-span-12">
              {(photos || []).map((p, index) => (
                <div
                  style={{ cursor: 'pointer' }}
                  key={p.thumbnail}
                  className="col-span-6 md:col-span-4 m-b-10"
                >
                  <div
                    onClick={() => {
                      this.openPhoto(p.key, index);
                    }}
                    className={['relative', classes.photoButton].join(' ')}
                    style={{ position: 'relative' }}
                  >
                    {!p.default && (
                      <Button
                        style={{
                          position: 'absolute',
                          top: '.5rem',
                          left: '.5rem',
                        }}
                        className={[
                          `sm-primary','p-2','m-t-10`,
                          classes.defBtn,
                        ].join(' ')}
                        text="Make Default"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          makeDefaultPhoto(p.id, storage.id);
                        }}
                      />
                    )}
                    {/*
                      <button
                        className={cx(
                          'btn bg-red absolute top-0 right-0',
                          s.deletePhoto
                        )}
                        onClick={() => {
                          if (
                            // eslint-disable-next-line no-restricted-globals
                            confirm(
                              'Are you sure you want to delete this photo?'
                            )
                          ) {
                            deletePhoto(storage.id, p.id);
                          }
                        }}
                      >
                        <i className="fa fa-times p-5" />
                      </button>
                      */}
                    {/* <a
                      className=""
                      rel="popover"
                      target="blank"
                      href={p.photo && p.photo.url && p.photo.url()}
                    > */}
                    <ImageLoader
                      className={s.storagePhotoContainer}
                      src={p.thumbnail}
                      preloader={() => <Loading />}
                    >
                      Image load failed!
                    </ImageLoader>
                    {/* </a> */}
                  </div>
                </div>
              ))}
              {(!photos || photos.length === 0) && (
                <div
                  className="col-span-12 text-center"
                  style={{ textAlign: 'center' }}
                >
                  <p className="text-center m-b-0">
                    No photos found for this storage.
                  </p>
                </div>
              )}
            </div>
            <div className="grid grid-cols-12 center col-span-12">
              <div className="col-span-12 center">
                <Button
                  className={`${s.addPhoto} center`}
                  onClick={() => {
                    this.fileUpload.click();
                  }}
                  text="Add Photos"
                >
                  <input
                    className={s.addPhotoInput}
                    type="file"
                    multiple
                    accept="image/*"
                    ref={input => {
                      this.fileUpload = input;
                    }}
                    onChange={() => {
                      const files = [];
                      for (
                        let i = 0;
                        i < this.fileUpload.files.length;
                        i += 1
                      ) {
                        const file = this.fileUpload.files[i];
                        encodeImageFileAsURL(file, res => {
                          files.push(res);
                        });
                      }
                      const i = setInterval(() => {
                        if (files.length === this.fileUpload.files.length) {
                          addPhotos(storage.id, files);
                          clearInterval(i);
                        }
                      }, 1000);
                    }}
                  />
                </Button>
              </div>
              <div className="col-span-12">
                {uploading && (
                  <div className="alert alert-info" role="alert">
                    Uploading Photos...
                    <span
                      className={cx(
                        s.storagePhotoStatus,
                        'pretty circle smooth plain',
                        uploading && 'info',
                        uploadSuccess && 'success',
                        uploadFailed && 'danger'
                      )}
                    >
                      <input
                        id={`select_${storage.id}`}
                        type="checkbox"
                        readOnly
                        checked
                      />
                      <label htmlFor={`select_${storage.id}`}>
                        {uploading && <i className="fa fa-spinner p-5" />}
                        {uploadSuccess && <i className="fa fa-check p-5" />}
                        {uploadFailed && <i className="fa fa-times p-5" />}
                      </label>
                    </span>
                  </div>
                )}
              </div>
            </div>
            {/*-------*/}
            {storageParts && storageParts.length > 0 ? (
              <h3 className="sub-title m-t-10 m-b-20 col-span-12">Parts</h3>
            ) : null}
            <div className="grid col-span-12 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 md:grid-cols-6">
              {storageParts.map((storagePart, index) => {
                const statusClass =
                  (storagePart.status === StorageStatus.delivered &&
                    'bg-blue') ||
                  (storagePart.status === StorageStatus.in && 'bg-green') ||
                  (storagePart.status === StorageStatus.stored && 'bg-green') ||
                  (storagePart.status === StorageStatus.fordelivery &&
                    'bg-yellow') ||
                  (storagePart.status === StorageStatus.forpickup &&
                    'bg-yellow');

                let statusText = format(storagePart.status);
                if (storagePart.status === StorageStatus.forpickup) {
                  const lastRequest = requests.find(
                    r =>
                      r.type === RequestType.pickup &&
                      r.storages.find(st => st.id === storage.id) !== -1
                  );
                  if (lastRequest) {
                    statusText = `Pickup on ${formatDate(lastRequest.date)}`;
                  }
                }
                if (storagePart.status === StorageStatus.fordelivery) {
                  const lastRequest = requests.find(
                    r =>
                      (r.type === RequestType.delivery ||
                        r.type === RequestType.newbox) &&
                      r.storages.find(st => st.id === storage.id) !== -1
                  );
                  if (lastRequest) {
                    statusText = `Delivery on ${formatDate(lastRequest.date)}`;
                  }
                }

                let photoIndex = this.state.allPartsPhotos.findIndex(
                  i => i.id === storagePart.id && i.default
                );

                if (photoIndex === -1) {
                  photoIndex = this.state.allPartsPhotos.findIndex(
                    i => i.id === storagePart.id
                  );
                }
                const photo = this.state.allPartsPhotos[photoIndex];

                //const photo = this.props.partPhotos[photoIndex];

                return (
                  <div
                    onClick={() => {
                      if (photo) {
                        this.openPartPhoto(storagePart.id, photoIndex);
                      }
                    }}
                    key={storagePart.id}
                    className={cx(s.storageCol)}
                  >
                    <button className={`${s.storageBox} box`}>
                      {photo && (
                        <ImageLoader
                          className={s.storageImage}
                          src={photo.thumbnail}
                          imgProps={{ alt: storagePart.name }}
                          preloader={() => <Loading />}
                        >
                          Image load failed!
                        </ImageLoader>
                      )}
                      {!photo && (
                        <div className={s.storageImage}>
                          <i
                            className="fa fa-archive fa-3x silver"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      <div className={s.storageCode}>
                        {storagePart.name || storagePart.code || 'Unassigned'}
                        <span className="pl-1">
                          {!storagePart.storagetype.tracking
                            ? `(${storagePart.qty})`
                            : ''}
                        </span>
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>
            {/*-------*/}
          </div>
          {/* </div> */}
        </div>
        {this.state.isPhotoOpen ? (
          <PhotoBox
            storageId={storage.id}
            cancel={() => this.setState({ isPhotoOpen: false })}
            photos={photos}
            size={photos.length}
            initialPhotoIndex={this.state.photoIndex}
            makeDefaultPhoto={makeDefaultPhoto}
          />
        ) : null}
        {this.state.isPartPhotoOpen ? (
          <PhotoBox
            storageId={this.state.currentPartId}
            cancel={() => this.setState({ isPartPhotoOpen: false })}
            photos={this.state.allPartsPhotos}
            size={this.state.allPartsPhotos.length}
            initialPhotoIndex={this.state.partPhotoIndex}
            parts={true}
            removeMakeDefault={true}
          />
        ) : null}
      </div>
    ) : (
      <div className={s.loadingContainer}>
        <Loading />
      </div>
    );
  }
}

// StoragePage.propTypes = {
//     urlParams: PropTypes.object.isRequired,
//     getPhotos: PropTypes.func.isRequired,
//     addPhotos: PropTypes.func.isRequired,
//     deletePhoto: PropTypes.func.isRequired,
//     updateStorage: PropTypes.func.isRequired,
//     storage: PropTypes.object.isRequired,
//     photos: PropTypes.arrayOf(PropTypes.object).isRequired,
//     addPhotosStatus: PropTypes.string,
//     config: PropTypes.object.isRequired,
// };

const mapStateToProps = (state, props) => {
  //const { urlParams } = props;
  //const storages = (state as any).storageParts.allStorages;
  //const storageCode = urlParams.storageCode;

  let photos;

  const storage = (state as any).storageParts.currentStorage;

  if (storage) {
    photos =
      state.photos && state.photos.filter(p => p.storageId === storage.id);
  }
  /*let storage;
  let photos;
  if (storageCode) {
    storage = storages && storages.find(st => st.code === storageCode);
    console.log({ storage });
    if (!storage) {
      // Some storage might not have code assigned. So id will be passed.
      storage =
        storages && storages.find(st => st.id === parseInt(storageCode, 10));
    }

    if (!storage) {
      storage = [];
    }
  }

  if (storage) {
    photos =
      state.photos && state.photos.filter(p => p.storageId === storage.id);
  }*/

  return {
    storage,
    photos,
    addPhotosStatus: state.photos && state.photos.addPhotosStatus,
    config: state.config,
    storageParts: state.storageParts.storageParts,
    isCurrentStorageLoading: state.storageParts.isCurrentStorageLoading,
    partPhotos: state.storageParts.partPhotos,
    requests: state.requests,
  };
};

export default connect(mapStateToProps, dispatch =>
  bindActionCreators(
    {
      ...appActions,
      ...storageActions,
      ...storagePartsActions,
      ...photoActions,
    },
    dispatch
  )
)(StoragePage);
